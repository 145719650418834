@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Product Sans';
  src: local('Product Sans'), url(/public/Fonts/ProductSans.woff) format('woff');
}

body {
  font-family: 'Product Sans' !important;
}
.mx-handler {
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 1024px) {
  .mx-handler {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}
.schedule-container {
  position: relative;
  overflow: hidden;
  height: fit-content;
  width: inherit;
}

#fade-in-1 {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  width: inherit;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transform: translateX(0);
  background: transparent;
}

#fade-in-2 {
  height: auto;
  width: inherit;
  background: transparent;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transform: translateX(100%);
}

#fade-in-1.inactive {
  transform: translateX(-100%);
}
#fade-in-2.active {
  transform: translateX(0);
}
.contain {
  margin: 0;
  margin-top: 5rem !important;
  width: 100%;
  position: relative;
}

.contain table {
  margin: auto;
  width: 70%;
}
.contain table {
  background: rgb(218, 218, 218);
}

.iframe > .image-container {
  display: none !important;
}
